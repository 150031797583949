export function useInputFormatters() {
  const sirenFormatter = (input, length = 9) => {
    if (input !== null && input !== undefined) {
      // Used for live formatting input
      let siren = input.replace(/\s/g, '').substring(0, length)
      siren = siren.replace(/(.{3})/g, '$1 ').replace(/(^\s+|\s+$)/, '')
      return siren
    }

    return input
  }

  const phoneFormatter = input => {
    if (input !== null && input !== undefined) {
      let phone = input.replace(/\s/g, '')
      phone = phone.replace(/\s/g, '').substring(0, 10)
      phone = phone.replace(/(.{2})/g, '$1 ').replace(/(^\s+|\s+$)/, '')
      return phone
    }

    return input
  }

  const lengthFormater = (input, length = 9) => {
    if (input !== null && input !== undefined) {
      // Used for live formatting input
      let siren = input.replace(/\s/g, '').substring(0, length)
      return siren
    }

    return input
  }
  return {
    sirenFormatter,
    phoneFormatter,
    lengthFormater,
  }
}
